import axios from "axios";
import { auth } from "@/core/services/auth";

let API_URL = process.env.VUE_APP_API_URL;

if (API_URL.endsWith("/")) API_URL = API_URL.substring(0, API_URL.length - 1);

const URL_V1 = `${API_URL}/api/v1`;

const formDataHeaders = {
  headers: { "Content-Type": "multipart/form-data" },
};

export default {
  getTemporaryId() {
    const path = `${URL_V1}/login/get-auth-temporary-id`;
    return axios.get(path, auth.getHeaders());
  },

  getToken(id) {
    const path = `${URL_V1}/login/get-token?id=${id}`;
    return axios.get(path);
  },

  get(path) {
    return axios.get(path, auth.getHeaders());
  },

  post(path, payload, multy = false) {
    // fix this shit with header multy
    return axios.post(path, payload, auth.getHeaders(multy));
  },

  put(path, payload) {
    return axios.put(path, payload, auth.getHeaders());
  },

  delete(path) {
    return axios.delete(path, auth.getHeaders());
  },

  ///////////////////////////////////////////////////////

  getUser() {
    const path = `${URL_V1}/users/me`;
    return this.get(path);
  },

  saveUser(jsonData) {
    const path = `${URL_V1}/users/me`;
    return axios.put(path, jsonData, auth.getHeaders());
  },

  getAccessToken(formData) {
    const path = `${URL_V1}/login/access-token`;
    return axios.post(path, formData, formDataHeaders);
  },

  getAuthTokenByCert(jsonData) {
    const path = `${URL_V1}/openid/signature_auth`;
    return axios.post(path, jsonData);
  },

  sendActivationSms(jsonData) {
    const path = `${URL_V1}/request_phone_confirmation`;
    return this.post(path, jsonData);
  },

  sendCheckSmsCode(jsonData) {
    const path = `${URL_V1}/phone_confirmation`;
    return this.post(path, jsonData);
  },

  sendEmailActivation(jsonData) {
    const path = `${URL_V1}/request_email_confirmation`;
    return this.post(path, jsonData);
  },

  getUsers() {
    return this.get(`${URL_V1}/users?limit=9999`);
  },

  createUser(payload) {
    return this.post(`${URL_V1}/users`, payload);
  },

  updateUser(payload, id) {
    return this.put(`${URL_V1}/users/${id}`, payload);
  },

  deleteUser(id) {
    return this.delete(`${URL_V1}/users/${id}`);
  },

  getGroups() {
    return this.get(`${URL_V1}/groups`);
  },

  createGroup(payload) {
    return this.post(`${URL_V1}/groups`, payload);
  },

  updateGroup(payload, id) {
    return this.put(`${URL_V1}/groups/${id}`, payload);
  },

  deleteGroup(id) {
    return this.delete(`${URL_V1}/groups/${id}`);
  },
};
