import router from "../router";

function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

class Auth {
  setToken(token) {
    localStorage.setItem("token", token);
  }

  getToken() {
    return localStorage.getItem("token");
  }

  getParsedTokenData() {
    return JSON.parse(parseJwt(this.getToken()).sub);
  }

  logOut() {
    localStorage.removeItem("token");
    router.push({ name: "login" });
  }

  loggedIn() {
    return !!this.getToken();
  }

  getHeaders(multy = false) {
    const token = this.getToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    if (multy) {
      config.headers["Content-Type"] = "multipart/form-data";
    }
    return config;
  }
}

export const auth = new Auth();
